const Util = {

  // Extends a `base` class with `mixins`.
  mixin: function(base, ...mixins) {
    return mixins.reduce((cls, mixin) => mixin(cls), base);
  },

  keyCodes: {
    backspace: 8,
    tab: 9,
    enter: 13,
    escape: 27,
    arrow_left: 37,
    arrow_up: 38,
    arrow_right: 39,
    arrow_down: 40,
    'delete': 46
  },

  longPressDuration: 500,
  doubleTapTimeout: 300,
  minSwipeDistance: 15,

  // Takes a character (string of length 1) and returns the corresponding keyCode,
  // as given in evt.which property of the keydown event.
  keyCodeFromChar: function(chr) {
    return chr.charCodeAt(0) - 32;
  },

  // Takes an Event (mouse or touch) object and returns an object with pageX/Y, and clientX/Y properties.
  // If the event is a touch event, it assumes it's a single-finger event.
  pointerCoords: function(evt) {
    if (evt.originalEvent) {
      evt = evt.originalEvent;
    }
    const source = evt.changedTouches ? evt.changedTouches[0] : evt;
    return {
      pageX: source.pageX,
      pageY: source.pageY,
      clientX: source.clientX,
      clientY: source.clientY
    };
  },

  mmPerInch: 25.4,
  ptPerMm: 2.83464567,

  // Converts value in inches to milimeters.
  in2mm: function(inch) {
    return inch * Px.Util.mmPerInch;
  },

  // Converts value in milimeters to inches.
  mm2in: function(mm) {
    return mm / Px.Util.mmPerInch;
  },

  // Converts postcript point units to millimetres.
  pt2mm: function(pt) {
    return pt / 2.83464567;
  },

  // Converts millimetres to postscript point units.
  mm2pt: function(mm) {
    return mm * 2.83464567;
  },

  // Converts the angle `deg` from degrees into radians.
  toRadians: function(deg) {
    return Math.PI * deg / 180;
  },

  // Converts the angle `rad` from radians into degrees.
  toDegrees: function(rad) {
    return rad * 180 / Math.PI;
  },

  // Takes x and y coordinates of a vector in 2D space and an angle,
  // and returns the coordinates of the vector transposed to the
  // coordinate space rotated around the origin for the specified angle.
  rotatePoint: function(X, Y, rotation) {
    var radians = Px.Util.toRadians(rotation);

    var x = X*Math.cos(radians) + Y*Math.sin(radians);
    var y = Y*Math.cos(radians) - X*Math.sin(radians);

    return [x, y];
  },

  // Takes `width`, `height`, and optional `rotation` (in degrees) of
  // a rectangle and returns an array of [x, y] points that correspond to
  // the four vertices of the rectangle, where the origin of the coordinate
  // system lies in the center of the rectangle.
  //
  //  A-----------B
  //  |           |
  //  |     x     |
  //  |           |
  //  C-----------D
  //
  rectangleVertices: function(width, height, rotation) {
    var W = width/2;
    var H = height/2;

    // A, B, C, and D represent the four vertices of the rectangle, represented
    // in coordinates of the coo rdinate space rotated for `rotation` degrees around
    // the rectangle center.
    var A = Px.Util.rotatePoint(-W, -H, rotation);
    var B = Px.Util.rotatePoint(+W, -H, rotation);
    var C = Px.Util.rotatePoint(+W,  H, rotation);
    var D = Px.Util.rotatePoint(-W,  H, rotation);

    return [A, B, C, D];
  },

  // Takes an array of coordinates and returns an object with the
  // `min_x`, `max_x`, `min_y`, and `max_y` properties.
  extremeCoords: function(coords) {
    var xs = [];
    var ys = [];
    _.each(coords, function(c) {
      xs.push(c[0]);
      ys.push(c[1]);
    });

    var extremes = {
      min_x: Math.min.apply(null, xs),
      min_y: Math.min.apply(null, ys),
      max_x: Math.max.apply(null, xs),
      max_y: Math.max.apply(null, ys)
    };

    return extremes;
  },

  // Takes `width`, `height`, and `rotation` of a rectangle, and calculates
  // the dimensions of the circumscribed rectangle.
  // The idea is similar to: http://www.mathopenref.com/coordbounds.html
  circumscribedRectangleDimensions: function(width, height, rotation) {
    var vertices = Px.Util.rectangleVertices(width, height, rotation);
    var extremes = Px.Util.extremeCoords(vertices);
    var dimensions = {
      width: extremes.max_x - extremes.min_x,
      height: extremes.max_y - extremes.min_y
    };
    return dimensions;
  },

  // Takes `width` and `height` of the outer rectangle, and `rotation` and `inscribed_rect_aspect_ratio` of
  // the inner rectangle, and returns the dimensions of the inscribed rectangle.
  // Partially inspired by https://math.stackexchange.com/q/2179500, although the problem is not entirely the same.
  inscribedRectangleDimensions: function(width, height, rotation, inscribed_rect_aspect_ratio) {
    // We don't know the width and height of the inscribed rectangle, but we do know its aspect ratio.
    // The aspect ratio of rotated rectangle vertices stays the same if we scale the rectangle, so the
    // actual value of width/height doesn't matter at this point and we just use width=1, height=width/AR.
    var inscribed_vertices = Px.Util.rectangleVertices(1, 1/inscribed_rect_aspect_ratio, rotation);
    var extremes = Px.Util.extremeCoords(inscribed_vertices);
    var xdiff = extremes.max_x - extremes.min_x;
    var ydiff = extremes.max_y - extremes.min_y;
    var rad = Px.Util.toRadians(rotation);
    var cos = Math.abs(Math.cos(rad));
    var sin = Math.abs(Math.sin(rad));
    var inner_width;

    if (width/height >= xdiff/ydiff) {
      inner_width = height / (sin + (cos/inscribed_rect_aspect_ratio));
    } else {
      inner_width = width / (cos + (sin/inscribed_rect_aspect_ratio));
    }

    var dimensions = {
      width: inner_width,
      height: inner_width / inscribed_rect_aspect_ratio
    };

    return dimensions;
  },

  // Takes two segments, `segA` and `segB`, representend as arrays of length 4
  // of the form [x1, y1, x2, y2].
  // Returns the intersection point [x, y] if the segments intersect, null otherwise.
  // If the lines are parallel, they are considered to never intersect,
  // even if they lie on top of the each other.
  //
  // Based on:
  // http://www.ahristov.com/tutorial/geometry-games/intersection-segments.html
  //
  //                           x (x3, y3)
  //                          /
  //                    segB /
  //                        /
  //  (x1, y1) x-----------------------x (x2, y2)
  //                      /    segA
  //                     /
  //                    x (x4, y4)
  //
  lineIntersection: function(segA, segB) {
    var x1 = segA[0];
    var y1 = segA[1];
    var x2 = segA[2];
    var y2 = segA[3];

    var x3 = segB[0];
    var y3 = segB[1];
    var x4 = segB[2];
    var y4 = segB[3];

    var d = ((x1 - x2) * (y3 - y4)) - ((y1 - y2) * (x3 - x4));
    var intersection;

    if (d === 0) {
      intersection = null; // lines are parallel
    } else {
      var xi = ((x3 - x4) * (x1*y2 - y1*x2) - (x1 - x2) * (x3*y4 - y3*x4)) / d;
      var yi = ((y3 - y4) * (x1*y2 - y1*x2) - (y1 - y2) * (x3*y4 - y3*x4)) / d;
      // Need to hove some tolerance due to rounding errors.
      var tolerance = 0.0001;
      var is_on_segment =
        xi >= Math.min(x1, x2) - tolerance &&
        xi >= Math.min(x3, x4) - tolerance &&
        xi <= Math.max(x1, x2) + tolerance &&
        xi <= Math.max(x3, x4) + tolerance &&
        yi >= Math.min(y1, y2) - tolerance &&
        yi >= Math.min(y3, y4) - tolerance &&
        yi <= Math.max(y1, y2) + tolerance &&
        yi <= Math.max(y3, y4) + tolerance;

      if (is_on_segment) {
        intersection = [xi, yi];
      } else {
        intersection = null; // intersection lies outside the segments
      }
    }

    return intersection;
  },

  // Generates a GUID string of the form
  // "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
  // where x stands for a hex digit.
  guid: function() {
    var S4 = function() {
      return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+'-'+S4()+'-'+S4()+'-'+S4()+'-'+S4()+S4()+S4());
  },

  // Same as jQuery's parseXML, except that it doesn't try to do
  // anything if object is already an XML node.
  parseXML: function(str_or_xml) {
    var xml;
    if (_.isString(str_or_xml)) {
      var parser = new DOMParser();
      xml = parser.parseFromString(str_or_xml, 'application/xml');
      if (xml.documentElement.nodeName === 'parseerror') {
        throw new Error('Invalid XML: ' + str_or_xml);
      }
    } else if (str_or_xml && str_or_xml.childNodes) {
      xml = str_or_xml;
    } else {
      throw new Error("Don't know how to transform into XML: " + str_or_xml);
    }
    return xml;
  },

  // The opposite of Px.Util.parseXML - takes an XML document and returns a string.
  serializeXML: function(xml) {
    var serializer = new XMLSerializer();
    return serializer.serializeToString(xml);
  },

  // Walks DOM node depth-first and executes `func` on each node.
  // If `func` returns false for a node, that node's children are not walked.
  walkDOM: function(node, func) {
    var ret = func(node);
    if (ret !== false) {
      node = node.firstChild;
      while (node) {
        Px.Util.walkDOM(node, func);
        node = node.nextSibling;
      }
    }
  },

  // Just like Px.Util.walkDOM, but only walks Element-type nodes.
  walkDOMElements: function(node, func) {
    var ret = func(node);
    if (ret !== false) {
      node = node.firstElementChild;
      while (node) {
        Px.Util.walkDOMElements(node, func);
        node = node.nextElementSibling;
      }
    }
  },

  _countDigits: function(number) {
    if (number === 0) {
      return 1;
    } else {
      return Math.floor(Math.log10(Math.abs(number)) + 1);
    }
  },

  _absolutePrecision: function(number, options) {
    if (options.significant && options.precision > 0) {
      return options.precision - Px.Util._countDigits(number);
    } else {
      return options.precision;
    }
  },

  _roundNumber: function(number, options) {
    const precision = Px.Util._absolutePrecision(number, options);
    // A JS hackity-hack, see:
    // https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
    // Note this only works up to a certain precision size, since JS floats are not big decimals.
    return +(Math.round(number + `e${precision}`) + `e${-precision}`);
  },

  // Ports number formatting code from CMS::Filters::Number,
  // but with some simplifications and constraints:
  // - no support for arbitrary precision decimals
  // - no support for negative numbers
  // - no support for delimiter_pattern
  // - only default round_mode supported
  formatNumber: function(input, options) {
    const number = parseFloat(input);
    if (Number.isNaN(number) || !Number.isFinite(number)) {
      return input;
    }

    const defaults = {
      separator: '.',
      precision: 2
    };
    options = Object.assign(defaults, options);

    const separator = options.separator;
    let precision = options.precision;
    let formatted_string;

    const rounded = Px.Util._roundNumber(number, options);
    if (options.significant && precision > 0) {
      precision -= Px.Util._countDigits(rounded);
      if (precision < 0) {
        precision = 0;
      }
    }

    if (Number.isNaN(rounded) || !Number.isFinite(rounded) || Number.isInteger(rounded)) {
      formatted_string = rounded.toFixed(precision);
    } else {
      const s = rounded.toString() + (new Array(precision).fill('0')).join('');
      const splat = s.split('.',  2);
      formatted_string = `${splat[0]}.${splat[1].substring(0, precision)}`;
    }

    if (!Number.isNaN(parseFloat(formatted_string))) {
      const splat = formatted_string.split('.');
      const right = splat[1];
      const left = splat[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g , match => {
        return match + (options.delimiter || '');
      });
      formatted_string = [left, right].filter(s => !!s).join(separator);

      if (options.strip_insignificant_zeros) {
        // JS doesn't have a RegExp.escape function :(
        const escaped_separator = Array.from(separator).map(c => `\\u{${c.charCodeAt(0).toString(16)}}`).join('');
        const separator_regex = new RegExp(`${escaped_separator}$`, 'u');
        const decimals_regex = new RegExp(`(${escaped_separator})(\\d*[1-9])?0+$`, 'u');
        formatted_string = formatted_string.replace(decimals_regex, '$1$2').replace(separator_regex, '');
      }
    }

    return formatted_string;
  },

  // Ports currency formatting code from CMS::Filters::Number,
  // but with some simplifications and constraints (see `formatNumber`).
  formatCurrency: function(input, options) {
    options = options || Px.config.currency_format;
    input = String(input).trim();
    const formatted_number = Px.Util.formatNumber(input, options);
    const format = options.format || '%u%n';
    return format.replace(new RegExp('%n', 'g'), formatted_number).replace(new RegExp('%u', 'g'), options.unit || '');
  },

  // Takes `number` and roundes to the nearest multiple of `step`.
  // Example: roundToNearest(200, 90) => 180
  roundToNearest: function(number, step) {
    let result = Math.round(number / step) * step;

    if (Object.is(result, -0)) {
      // We don't want weird negative zeros.
      result = 0;
    }

    return result;
  },

  // Truncates a filename by removing excess characters from the middle and replacing them with three dots.
  truncateFilename: function(filename, maxlen) {
    if (filename.length <= maxlen) {
      return filename;
    }

    const idx = Math.floor(filename.length / 2);
    let front = filename.substring(0, idx);
    let back = filename.substring(idx);
    let working_front = true;

    while (filename.length > maxlen) {
      if (working_front) {
        front = front.substring(0, front.length - 1);
        working_front = false;
      } else {
        back = back.substring(1);
        working_front = true;
      }
      filename = `${front}...${back}`;
    }

    return filename;
  },

  // Escapes unsafe HTML characters.
  escapeHTML: function(str) {
    return str
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  },

  // Same as String.prototype.codePointAt in ES6 (available in modern browsers, but not IE <= 10).
  codePointAt: function(string, index) {
    if (string.codePointAt) {
      // Use native codePointAt, if available.
      return string.codePointAt(index);
    }
    var size = string.length;
    // Account for out-of-bounds indices:
    if (index < 0 || index >= size) {
      return undefined;
    }
    // Get the first code unit
    var first = string.charCodeAt(index);
    var second;
    if ( // check if it’s the start of a surrogate pair
      first >= 0xD800 && first <= 0xDBFF && // high surrogate
      size > index + 1 // there is a next code unit
    ) {
      second = string.charCodeAt(index + 1);
      if (second >= 0xDC00 && second <= 0xDFFF) { // low surrogate
        // http://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
        return (first - 0xD800) * 0x400 + second - 0xDC00 + 0x10000;
      }
    }
    return first;
  },

  // Access files from a drop event in a cross-browser comatible way.
  getDataTransferFiles: function(evt) {
    const original_evt = evt.originalEvent || evt;
    const dt = original_evt.dataTransfer;
    let files = dt.files;
    // Use DataTransferItemList interface to access the file(s), if browser supports it.
    if (dt.items) {
      files = [];
      for (let i = 0; i < dt.items.length; i++) {
        if (dt.items[i].kind === 'file') {
          files.push(dt.items[i].getAsFile());
        }
      }
    }
    return files;
  },

  // Opens a popup window and returns a reference to it.
  // `opts` can contain `width`, `height`, and `name` properties.
  openPopup: function(url, opts) {
    var screenX = typeof window.screenX !== 'undefined' ? window.screenX : window.screenLeft;
    var screenY = typeof window.screenY !== 'undefined' ? window.screenY : window.screenTop;
    var clientWidth = typeof window.outerWidth !== 'undefined' ? window.outerWidth : document.documentElement.clientWidth;
    var clientHeight = typeof window.outerHeight !== 'undefined' ? window.outerHeight : (document.documentElement.clientHeight - 22);
    var popupWidth = opts.width || 600;
    var popupHeight = opts.height || 400;
    var popupX = parseInt(screenX + ((clientWidth - popupWidth) / 2), 10);
    var popupY = parseInt(screenY + ((clientHeight - popupHeight) / 2.5), 10);
    var popupFeatures = ('width=' + popupWidth + ',height=' + popupHeight + ',left=' + popupX + ',top=' + popupY + ',scrollbars=1,location=1,toolbar=0');
    var name = opts.name || _.uniqueId('pxeditor');

    return window.open(url, name, popupFeatures);
  },

  // ----------------
  // Color arithmetic
  // ----------------
  // Taken from https://stackoverflow.com/a/9493060/51397

  // Takes an object with r, g, and b properties in range [0 - 255]
  // and returns an object with h, s, and l properties, corresponding to hue,
  // saturation, and lightness values in range [0 - 1].
  rgbToHsl: function(rgb) {
    var r = rgb.r / 255;
    var g = rgb.g / 255;
    var b = rgb.b / 255;

    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      var d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      }
      h /= 6;
    }

    return {h: h, s: s, l: l};
  },

  // Takes an object with h, s, and l properties in range [0 - 1]
  // and returns an object with r, g, and b properties, correspnding to red,
  // green and blue values in range [0 - 255].
  hslToRgb: function(hsl) {
    var h = hsl.h;
    var s = hsl.s;
    var l = hsl.l;
    var r, g, b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      var hue2rgb = function hue2rgb(p, q, t) {
        if (t < 0) {
          t += 1;
        }
        if (t > 1) {
          t -= 1;
        }
        if (t < 1/6) {
          return p + (q - p) * 6 * t;
        }
        if (t < 1/2) {
          return q;
        }
        if (t < 2/3) {
          return p + (q - p) * (2/3 - t) * 6;
        }
        return p;
      };

      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;
      r = hue2rgb(p, q, h + 1/3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1/3);
    }

    return {r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255)};
  },

  // Takes an object with c, m, y, and k properties in range [0 - 1]
  // and returns an object with r, g, and b properties, correspnding to red,
  // green and blue values in range [0 - 255].
  rgbToCmyk: function(rgb) {
    const r = rgb.r / 255;
    const g = rgb.g / 255;
    const b = rgb.b / 255;
    const k = 1 - Math.max(r, g, b);
    if (k === 1) {
      return {c: 0, m: 0, y: 0, k: 1};
    } else {
      return {
        c: (1 - r - k) / (1 - k),
        m: (1 - g - k) / (1 - k),
        y: (1 - b - k) / (1 - k),
        k: k
      };
    }
  },

  // Takes an object with r, g, and b properties in range [0 - 255]
  // and returns an object with c, m, y and k properties.
  cmykToRgb: function(cmyk) {
    return {
      r: Math.round(255 * (1 - cmyk.c) * (1 - cmyk.k)),
      g: Math.round(255 * (1 - cmyk.m) * (1 - cmyk.k)),
      b: Math.round(255 * (1 - cmyk.y) * (1 - cmyk.k))
    };
  },

  // Takes a RGB color string in the form of #0ABFC3.
  // Only 6-letter hex colors are currently supported.
  // Returns an object with r, g, and b properties.
  parseRgbString: function(str) {
    str = str.replace('#', '');
    var r = parseInt(str[0] + str[1], 16);
    var g = parseInt(str[2] + str[3], 16);
    var b = parseInt(str[4] + str[5], 16);
    return {r: r, g: g, b: b};
  },

  // Takes a cmyk color representation of the form "cmyk(c,m,y,k)" and parses it
  // into an object with `c`, `m`, `y`, and `k` keys with values in the range [0 - 1].
  parseCmykString: function(str) {
    const components = str.replace('cmyk(', '').replace(')', '').split(',').map(n => parseInt(n, 10) / 100);
    return {
      c: components[0],
      m: components[1],
      y: components[2],
      k: components[3]
    };
  },

  // Takes an object with r, g, and b properties, which should be integers
  // in range 0 - 255, and returns a hex string of the form #0ABFC3;
  generateRgbString: function(rgb) {
    var toHex = function(d) {
      var hex = d.toString(16);
      if (hex.length === 1) {
        hex = '0' + hex;
      }
      return hex.toUpperCase();
    };
    return '#' + toHex(rgb.r) + toHex(rgb.g) + toHex(rgb.b);
  },

  generateCmykString: function(cmyk) {
    const format = c => Math.round(cmyk[c] * 100);
    return `cmyk(${format('c')},${format('m')},${format('y')},${format('k')})`;
  },

  // Takes a string color representation ("#ffaabb", "cmyk(0,0,0,1)", ...) and returns
  // a RGB hex representation that can be used directly in HTML.
  colorForDisplay: function(color_str) {
    if (color_str.slice(0, 4) === 'cmyk') {
      const cmyk = Px.Util.parseCmykString(color_str);
      const rgb = Px.Util.cmykToRgb(cmyk);
      return Px.Util.generateRgbString(rgb);
    } else {
      return color_str;
    }
  },

  // Returns true if color is considered dark.
  isColorDark: function(color, lightness_limit) {
    lightness_limit = lightness_limit || 0.6;
    let rgb;
    if (color.slice(0, 4) === 'cmyk') {
      rgb = Px.Util.cmykToRgb(Px.Util.parseCmykString(color));
    } else {
      rgb = Px.Util.parseRgbString(color);
    }
    const hsl = Px.Util.rgbToHsl(rgb);
    return hsl.l < lightness_limit;
  },

  // ----------------
  // Matrix functions
  // ----------------

  // Following matrix functions were designed to be used for
  // calculating SVG transformations.
  // The matrices are represented as 6-element arrays [a, b, c, d, e, f],
  // that correpond to the following general SVG transformation matrix:
  //
  // | a c e |
  // | b d f |
  // | 0 0 1 |
  //
  // See: http://www.w3.org/TR/SVG/coords.html#EstablishingANewUserSpace


  // Returns a matrix representing translation of `tx`, `ty`.
  // Corresponds to the "translate(tx, ty)" SVG transform attribute.
  translationMatrix: function(tx, ty) {
    return [1, 0, 0, 1, tx, ty];
  },

  // Returns a matrix representing rotation for `deg` degrees,
  // around the origin point `ox`, `oy`.
  // Corresponds to the "rotate(deg, ox, oy)" SVG transform attribute.
  rotationMatrix: function(deg, ox, oy) {
    var rad = Px.Util.toRadians(deg);
    var cos = Math.cos(rad);
    var sin = Math.sin(rad);
    var T1 = Px.Util.translationMatrix(ox, oy);
    var T2 = Px.Util.translationMatrix(-ox, -oy);
    var R = [cos, sin, -sin, cos, 0, 0];
    return Px.Util.multiplyMatrices([T1, R, T2]);
  },

  // Helper functions that returns a product of matrices `A` and `B`.
  multiply2Matrices: function(A, B) {
    var a = A[0] * B[0] + A[2] * B[1];
    var b = A[1] * B[0] + A[3] * B[1];
    var c = A[0] * B[2] + A[2] * B[3];
    var d = A[1] * B[2] + A[3] * B[3];
    var e = A[0] * B[4] + A[2] * B[5] + A[4];
    var f = A[1] * B[4] + A[3] * B[5] + A[5];
    return [a, b, c, d, e, f];
  },

  // Takes an array of matrices `Ms`, and returns a new matrix
  // that corresponds to the product of matrices in `Ms`.
  multiplyMatrices: function(Ms) {
    var P = Ms[0];
    for (var i = 1; i < Ms.length; i++) {
      P = Px.Util.multiply2Matrices(P, Ms[i]);
    }
    return P;
  },

  // Takes a transformation matrix `M` and a `point` represented
  // as an array [x, y], and returns a new point with transformation
  // `M` applied.
  applyMatrix: function(M, point) {
    var x = point[0];
    var y = point[1];
    var Mx = M[0] * x + M[2] * y + M[4];
    var My = M[1] * x + M[3] * y + M[5];
    return [Mx, My];
  },

  // Takes a matrix `M` and returns its inversion.
  invertMatrix: function(M) {
    var a = M[0];
    var b = M[1];
    var c = M[2];
    var d = M[3];
    var e = M[4];
    var f = M[5];

    var det = a*d - c*b;
    var I = [d, -b, -c, a, c*f - e*d, e*b - a*f];

    I = _.map(I, function(x) {
      return x / det;
    });

    return I;
  },

  isMapEqual: function(map1, map2) {
    if (map1.size !== map2.size) {
      return false;
    }
    const keys = Array.from(map1.keys());
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (map1.get(key) !== map2.get(key)) {
        return false;
      }
    }
    return true;
  },

  // Takes a function and returns a new function that works the same way,
  // but can be cancelled. Useful for callbacks that you want to be able to
  // cancel but don't have any other mechanism to do so.
  // Taken from: https://gist.github.com/insin/388285219a976c99c2b0
  cancellableFunction: function(fn) {
    let cancelled = false;

    const cancellable = function() {
      if (!cancelled) {
        fn.apply(null, arguments);
      }
    };

    cancellable.cancel = function() {
      cancelled = true;
      if (typeof fn.onCancel === 'function') {
        fn.onCancel();
      }
    };

    return cancellable;
  },

  // Returns a media query that matches 'mobile' or 'small_desktop' mode.
  matchMedia: function(mode) {
    if (mode === 'mobile') {
      return window.matchMedia('(max-device-width: 599px), (max-device-height: 599px)');
    } else if (mode === 'small_desktop') {
      return window.matchMedia('(max-height: 1079px)');
    } else {
      throw new Error(`The 'mode' parameter needs to be one of: 'mobile', 'small_desktop'. Got: ${mode}`);
    }
  },

  // This parses string values that follow a similar logic to CSS padding and margin,
  // where you can specify the value with 1 number (when all padding/margins are equal),
  // 2, 3, or 4 numbers.
  // It differs from CSS in that it allows optional commas as value separators,
  // inspired by SVG attributes such as the `d` attribute of `path`.
  // We use this for print page bleeds and margins among other things.
  // It returns an array of 4 numerical values representing the top, right, bottom, and left values.
  parseCSSRectValues: function(str)  {
    let parsed;
    let p, px, py, pt, pb;
    const parts = (str || '').split(/[\s,]/).filter(s => s).slice(0, 4).map(s => parseFloat(s));
    switch (parts.length) {
    case 0:
      parsed = [0, 0, 0, 0];
      break;
    case 1:
      p = parts[0];
      parsed = [p, p, p, p];
      break;
    case 2:
      py = parts[0];
      px = parts[1];
      parsed = [py, px, py, px];
      break;
    case 3:
      pt = parts[0];
      px = parts[1];
      pb = parts[2];
      parsed = [pt, px, pb, px];
      break;
    case 4:
      parsed = parts;
      break;
    }
    return parsed;
  },

  // Helper for debugging issues on mobile where JS console is not always available.
  debug: function(text) {
    this._debug_count = this._debug_count || 1;
    let div = document.body.querySelector('#pixfizz-debug');
    if (!div) {
      div = document.createElement('div');
      div.id = 'pixfizz-debug';
      div.style.position = 'fixed';
      div.style.top = '0';
      div.style.left = '50%';
      div.style.transform = 'translateX(-50%)';
      div.style.background = 'yellow';
      div.style.padding = '4px 8px';
      div.style.fontSize = '10px';
      div.style.zIndex = '10000000';
      document.body.appendChild(div);
    }
    clearTimeout(this._debug_timeout);
    this._debug_timeout = setTimeout(() => document.body.removeChild(div), 1000);
    div.innerText = `${this._debug_count++}: ${text}`;
  }

};

Px.Util = Util;
export default Util;
