// TODO: I have a feeling that shadow DOM is completely unnecessary here??
Px.CMS.OptionSelector = class OptionSelector extends HTMLElement {

  constructor() {
    super();

    this._timeout_id = null;
    this._last_values = null;

    this.updateChildOptions = this.updateChildOptions.bind(this);
    this.handleSlotChange = this.handleSlotChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateChildrenAndScheduleChangeEvent = this.updateChildrenAndScheduleChangeEvent.bind(this);

    this.attachShadow({mode: 'open'});
    this.shadowRoot.innerHTML = '<slot></slot>';
    const slot = this.shadowRoot.querySelector('slot');
    slot.style.boxSizing = 'border-box';
    this.mutation_observer = new MutationObserver(this.updateChildrenAndScheduleChangeEvent);
  }

  connectedCallback() {
    const slot = this.shadowRoot.querySelector('slot');
    this.addEventListener('change', this.handleChange);
    slot.addEventListener('slotchange', this.handleSlotChange);
  }

  disconnectedCallback() {
    clearTimeout(this._timeout_id);
    this._last_values = null;
    this.mutation_observer.disconnect();
    const slot = this.shadowRoot.querySelector('slot');
    this.removeEventListener('change', this.handleChange);
    slot.removeEventListener('slotchange', this.handleSlotChange);
  }

  connectMutationObserver() {
    const nodes = this.shadowRoot.querySelector('slot').assignedNodes();
    for (const node of nodes) {
      this.mutation_observer.observe(node, {
        attributes: true,
        childList: true,
        subtree: true
      });
    }
  }

  disconnectMutationObserver() {
    this.mutation_observer.disconnect();
  }

  handleChange(evt) {
    // We want to react to change events emitted by any slotted element,
    // but not by this element itself.
    if (evt.target !== this) {
      this.updateChildrenAndScheduleChangeEvent();
    }
  }

  // The slotchange event fires when the value of slot.assignedNodes() changes,
  // but does not fire when the contents of assigned nodes change.
  // We need to use a MutationObserver to detect the changes from the slotted light DOM nodes.
  handleSlotChange() {
    this.updateChildrenAndScheduleChangeEvent();
  }

  updateChildOptions() {
    this.querySelectorAll('px-option[trigger]').forEach(option => {
      const trigger = option.getAttribute('trigger');
      const parent_option = option.parentElement.closest('px-option[code]');
      const parent_code = parent_option.getAttribute('code');
      const parent_input = parent_option.querySelectorAll(`[name$="[${parent_code}]"]`);
      if (parent_input.length) {
        const input_type = parent_input[0].type;
        if (input_type === 'checkbox' || input_type === 'radio') {
          let checked = null;
          parent_input.forEach(input => {
            if (input.checked) {
              checked = input;
            }
          });
          if (checked && checked.value === trigger && !checked.disabled) {
            this.enableChild(option);
          } else {
            this.disableChild(option);
          }
        } else if (parent_input[0].nodeName === 'SELECT') {
          if (parent_input[0].value === trigger && !parent_input[0].disabled) {
            this.enableChild(option);
          } else {
            this.disableChild(option);
          }
        }
      }
    });
  }

  updateChildrenAndScheduleChangeEvent() {
    // Disable mutation observer while updating children, so that updates don't in turn trigger
    // an avalanche of further updates.
    this.disconnectMutationObserver();
    // Update the child nodes.
    this.updateChildOptions();
    // Re-connect the mutation observer.
    this.connectMutationObserver();

    clearTimeout(this._timeout_id);

    this._timeout_id = setTimeout(() => {
      const new_values = JSON.stringify(this.values());
      const last_values = this._last_values;
      if (new_values !== last_values) {
        this._last_values = new_values;
        // Don't trigger the change event if this is the first time we're rendering.
        if (last_values !== null) {
          const event = new Event('change', {bubbles: true});
          this.dispatchEvent(event);
        }
      }
    });
  }

  values(opts) {
    const options = Object.assign({
      skipInvalid: false,
      skipNoPricing: false,
      skipNoElementSubstitutions: false
    }, opts);

    const values = {};

    this.querySelectorAll('input, textarea, select').forEach(input => {
      let include_value = input.name && !input.disabled;
      if (options.skipInvalid) {
        include_value = include_value && input.validity.valid;
      }
      if (options.skipNoPricing) {
        include_value = include_value && !input.hasAttribute('data-px-no-pricing');
      }
      if (options.skipNoElementSubstitutions) {
        include_value = include_value && !input.hasAttribute('data-px-no-element-substitutions');
      }
      if (include_value) {
        if (input.type === 'checkbox' || input.type === 'radio') {
          if (input.checked) {
            values[input.name] = input.value;
          }
        } else {
          values[input.name] = input.value;
        }
      }
    });

    return values;
  }

  // NOTE:
  // Setting a property, even if the value is the same as the old property triggers a mutation event,
  // and because we are using a mutation observer, we don't want to trigger more mutations than neccessary,
  // and need to wrap mutation everything in an equality check :/

  enableChild(child_option) {
    if (child_option.hidden) {
      // Don't hide/show px-image-upload elements that are children of px-multi-image-upload,
      // because the parent px-multi-image-upload handles hiding/showing of its children.
      if (!child_option.closest('px-multi-image-upload')) {
        child_option.hidden = false;
      }
    }
    child_option.querySelectorAll('input, textarea, select, px-image-upload').forEach(element => {
      if (element.disabled) {
        // Don't touch child elements of px-image-upload, the component handles disabling of its children.
        if (!(element.parentElement && element.parentElement.matches('px-image-upload'))) {
          element.disabled = false;
        }
      }
    });
  }

  disableChild(child_option) {
    if (!child_option.hidden) {
      // Don't hide/show px-image-upload elements that are children of px-multi-image-upload,
      // because the parent px-multi-image-upload handles hiding/showing of its children.
      if (!child_option.closest('px-multi-image-upload')) {
        child_option.hidden = true;
      }
    }
    child_option.querySelectorAll('input, textarea, select, px-image-upload').forEach(element => {
      if (!element.disabled) {
        // Don't touch child elements of px-image-upload, the component handles disabling of its children.
        if (!(element.parentElement && element.parentElement.matches('px-image-upload'))) {
          element.disabled = true;
        }
      }
    });
  }

};

Px.CMS.OptionSelector.Option = class Option extends HTMLElement {};

customElements.define('px-option-selector', Px.CMS.OptionSelector);
customElements.define('px-option', Px.CMS.OptionSelector.Option);
