Px.CMS.MultiImageUpload = class MultiImageUpload extends HTMLElement {

  static get observedAttributes() {
    return [
      'lang',
      'button-class',
      'upload-button-label',
      'upload-button-label-one',
      'upload-button-label-other',
      'error-message-required'
    ];
  }

  constructor() {
    super();

    this.DEFAULT_TEXTS = {
      'upload-button-label': 'Upload {{count}} images',
      'upload-button-label-one': 'Upload {{count}} image',
      'upload-button-label-other': 'Upload {{count}} images',
      'error-message-required': 'Please upload images'
    };

    this.uploadButtonContainer = null;
    this.uploadButton = null;

    this.draggedElement = null;
    this.originalAttributes = null;
    this.uploadDialogActive = false;

    this.updateInterface = this.updateInterface.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  connectedCallback() {
    this.uploadButtonContainer = this.makeUploadButtonContainer();
    this.uploadButton = this.makeUploadButton();
    this.uploadButtonContainer.appendChild(this.uploadButton);

    this.prepend(this.uploadButtonContainer);

    this.addEventListener('dragstart', this.handleDragStart);
    this.addEventListener('dragend', this.handleDragEnd);
    this.addEventListener('dragenter', this.handleDragOver);
    this.addEventListener('dragover', this.handleDragOver);
    this.addEventListener('dragleave', this.handleDragLeave);
    this.addEventListener('drop', this.handleDrop);

    this.mutation_observer = new MutationObserver(mutations => this.handleMutations(mutations));
    this.mutation_observer.observe(this, {
      attributes: true,
      childList: true,
      subtree: true
    });

    this.updateInterface();
    // Update interface again after timeout because inside the connect callback
    // the element's children are not yet available.
    setTimeout(this.updateInterface);
  }

  disconnectedCallback() {
    this.uploadButtonContainer.remove();
    this.uploadButtonContainer = null;
    this.uploadButton = null;

    this.removeEventListener('dragstart', this.handleDragStart);
    this.removeEventListener('dragend', this.handleDragEnd);
    this.removeEventListener('dragenter', this.handleDragOver);
    this.removeEventListener('dragover', this.handleDragOver);
    this.removeEventListener('dragleave', this.handleDragLeave);
    this.removeEventListener('drop', this.handleDrop);

    this.mutation_observer.disconnect();
    this.mutation_observer = null;
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (this.isConnected) {
      switch (name) {
      case 'button-class':
        if (this.uploadButton) {
          this.uploadButton.className = newValue;
        }
      default:
        this.updateInterface();
      }
    }
  }

  // -------
  // Private
  // -------

  updateInterface() {
    const image_upload_elements = this.getImageUploadElements();
    const unfilled_upload_elements = image_upload_elements.filter(e => !e.value);
    const unfilled_count = unfilled_upload_elements.length;

    if (this.uploadButton) {
      const label = this.getText('upload-button-label', unfilled_count).replaceAll('{{count}}', unfilled_count);
      // It's important to only set innerText if the value changes,
      // otherwise it can trigger an endless mutation observer loop.
      if (this.uploadButton.innerText !== label) {
        this.uploadButton.innerText = label;
      }
      this.uploadButton.disabled = this.uploadDialogActive;
      this.setButtonValidity();
    }

    const hide_upload_button = image_upload_elements.every(e => e.value);
    const hide_image_uploads = image_upload_elements.every(e => !e.value);

    this.uploadButtonContainer.hidden = hide_upload_button;

    image_upload_elements.forEach(e => {
      e.closest('px-option').hidden = hide_image_uploads;
      const thumb = e.querySelector('.px-thumbnail');
      if (thumb) {
        thumb.setAttribute('draggable', 'true');
      }
    });
  }

  getImageUploadElements() {
    return Array.from(this.querySelectorAll('px-image-upload'));
  }

  getText(attr_name, count) {
    let text;
    if (typeof count === 'number' && window.Intl && window.Intl.PluralRules) {
      const lang_element = this.closest('[lang]');
      const lang = lang_element ? lang_element.getAttribute('lang') : 'en';
      const pluralizer = new Intl.PluralRules(lang);
      const plural_tag = pluralizer.select(count);
      const attr_with_tag = `${attr_name}-${plural_tag}`;
      text = this.getAttribute(attr_with_tag) || this.DEFAULT_TEXTS[attr_with_tag];
    }
    if (!text) {
      text = this.getAttribute(attr_name) || this.DEFAULT_TEXTS[attr_name];
    }
    return text;
  }

  extractImageUploadAttributes(image_upload) {
    const thumbnail = image_upload.querySelector('.px-thumbnail img');
    return {
      value: image_upload.value,
      img_src: image_upload.getAttribute('img-src'),
      img_width: image_upload.getAttribute('img-width'),
      img_height: image_upload.getAttribute('img-height'),
      thumbnail_src: thumbnail ? thumbnail.getAttribute('src') : '',
      crop_aspect_ratio: image_upload.getAttribute('crop-aspect-ratio')
    };
  }

  reorderAttributes(original_attributes, dragged_element, target_element) {
    const image_uploads = this.getImageUploadElements();

    const target_idx = image_uploads.indexOf(target_element);
    const dragged_idx = image_uploads.indexOf(dragged_element);

    const new_attributes = original_attributes.slice();
    const target_attrs = new_attributes[target_idx];
    const dragged_attrs = new_attributes[dragged_idx];

    if (this.getAttribute('drop-mode') === 'reorder') {
      // Reorder mode.
      if (target_idx > dragged_idx) {
        new_attributes.splice(target_idx + 1, 0, dragged_attrs);
        new_attributes.splice(dragged_idx, 1);
      } else {
        new_attributes.splice(dragged_idx, 1);
        new_attributes.splice(target_idx, 0, dragged_attrs);
      }
    } else {
      // Swap mode.
      new_attributes[target_idx] = dragged_attrs;
      new_attributes[dragged_idx] = target_attrs;
    }

    return new_attributes;
  }

  doDragoverPreview(image_upload) {
    const preview_attributes = this.reorderAttributes(this.originalAttributes, this.draggedElement, image_upload);
    this.applyThumbnailBackgrounds(preview_attributes);
  }

  undoDragoverPreview() {
    this.applyThumbnailBackgrounds(this.originalAttributes);
  }

  applyThumbnailBackgrounds(attributes) {
    const image_uploads = this.getImageUploadElements();
    image_uploads.forEach((image_upload, idx) => {
      const img = image_upload.querySelector('.px-thumbnail img');
      img.setAttribute('src', attributes[idx].thumbnail_src);
    });
  }

  applyImageAttributes(attributes) {
    const image_uploads = this.getImageUploadElements();
    const changed_uploads = [];
    image_uploads.forEach((image_upload, idx) => {
      let value = attributes[idx].value;
      const source_ar = Px.CMS.Helpers.parseCropAspectRatioString(attributes[idx].crop_aspect_ratio);
      const target_ar = Px.CMS.Helpers.parseCropAspectRatioString(image_upload.getAttribute('crop-aspect-ratio'));
      if (source_ar !== target_ar) {
        value = value.split('@')[0];
      }
      if (image_upload.value !== value) {
        image_upload.value = value;
        changed_uploads.push(image_upload);
      }
      image_upload.setAttribute('img-src', attributes[idx].img_src);
      image_upload.setAttribute('img-width', attributes[idx].img_width);
      image_upload.setAttribute('img-height', attributes[idx].img_height);
    });

    changed_uploads.forEach(image_upload => {
      const event = new Event('change', {bubbles: true});
      image_upload.dispatchEvent(event);
    });
  }


  // Button elements support native JS validation (setCustomValidity only).
  // Because we're not using any other visible UI form elements for image upload
  // (the inpu[type=file] is hidden), we set validity message on the buttons.
  // When ElementInternals is more widely supported, we might want to switch to using it instead:
  // https://developer.mozilla.org/en-US/docs/Web/API/ElementInternals
  setButtonValidity() {
    if (this.uploadButton) {
      const required_uploads = this.getImageUploadElements().filter(e => e.required);
      let errmsg = '';
      if (required_uploads.some(e => !e.value)) {
        errmsg = this.getText('error-message-required');
      }
      this.uploadButton.setCustomValidity(errmsg);
    }
  }

  makeUploadButtonContainer() {
    const container = document.createElement('div');
    container.className = 'px-multi-upload-button-container';
    container.setAttribute('data-px-no-rerender', 'true');
    return container;
  }

  makeUploadButton() {
    const button = document.createElement('button');
    button.innerText = 'Upload Images';
    if (this.getAttribute('button-class')) {
      button.className = this.getAttribute('button-class');
    }

    button.addEventListener('click', evt => {
      const dialog = document.createElement('px-upload-dialog');
      dialog.setAttribute('multiple', '');
      dialog.setAttribute('max-files', this.getImageUploadElements().filter(e => !e.value).length);

      const mapped_attributes = [
        'sources',
        'max-size',
        'gallery-id',
        'dropbox-app-key',
        'google-client-id',
        'error-message-filesize',
        'error-message-upload'
      ];

      const prefixed_attributes = [
        'upload-dialog-button-class',
        'upload-dialog-source-button-class',
        'upload-dialog-local-source-button-class',
        'upload-dialog-galleries-source-button-class',
        'upload-dialog-qr-source-button-class',
        'upload-dialog-url-source-button-class',
        'upload-dialog-confirm-button-class',
        'upload-dialog-select-all-button-class',
        'upload-dialog-picker-load-more-button-class',
        'upload-dialog-main-panel-title',
        'upload-dialog-galleries-picker-title',
        'upload-dialog-public-galleries-picker-title',
        'upload-dialog-picker-load-more-button-title',
        'upload-dialog-picker-ok-button-title',
        'upload-dialog-url-picker-title',
        'upload-dialog-back-button-title',
        'upload-dialog-close-button-title',
        'upload-dialog-source-button-local-text',
        'upload-dialog-source-button-galleries-text',
        'upload-dialog-source-button-public-galleries-text',
        'upload-dialog-source-button-url-text',
        'upload-dialog-qr-picker-info-text',
        'upload-dialog-no-galleries-text',
        'upload-dialog-no-images-text'
      ];

      mapped_attributes.forEach(attribute => {
        if (this.hasAttribute(attribute)) {
          dialog.setAttribute(attribute, this.getAttribute(attribute));
        }
      });

      prefixed_attributes.forEach(prefixed_attribute => {
        const attribute = prefixed_attribute.replace('upload-dialog-', '');
        if (this.hasAttribute(prefixed_attribute)) {
          dialog.setAttribute(attribute, this.getAttribute(prefixed_attribute));
        }
      });

      this.uploadButton.disabled = true;
      this.uploadDialogActive = true;

      dialog.addEventListener('upload-success', this.handleUploadSuccess.bind(this));
      dialog.addEventListener('close', () => {
        this.uploadDialogActive = false;
        this.updateInterface();
      });

      document.body.appendChild(dialog);
    });

    return button;
  }

  // --------------
  // Event handlers
  // --------------

  handleMutations(mutations) {
    for (let mutation of mutations) {
      if (mutation.type === 'attributes' && mutation.target.matches('px-image-upload')) {
        this.updateInterface();
        break;
      }

      const image_uploads_added = Array.from(mutation.addedNodes).some(node => {
        return node.nodeType === 1 && (node.matches('px-image-upload') || node.querySelector('px-image-upload'));
      });
      if (image_uploads_added) {
        this.updateInterface();
        break;
      }

      const image_uploads_removed = Array.from(mutation.removedNodes).some(node => {
        return node.nodeType === 1 && (node.matches('px-image-upload') || node.querySelector('px-image-upload'));
      });
      if (image_uploads_removed) {
        this.updateInterface();
        break;
      }
    }
  }

  handleUploadSuccess(evt) {
    const unfilled_element = this.getImageUploadElements().find(e => !e.value);
    if (!unfilled_element) {
      console.warn('Ignoring upload, all image options already filled', response);
      return;
    }

    const response = evt.detail.changedFile.result;
    const thumbnail_url = response.thumbnails[response.thumbnails.length - 1].url;

    unfilled_element.value = `db:${response.id}`;
    unfilled_element.setAttribute('img-src', thumbnail_url);
    unfilled_element.setAttribute('img-width', response.width);
    unfilled_element.setAttribute('img-height', response.height);

    this.updateInterface();
  }

  handleDragStart(evt) {
    const image_upload = evt.target.closest('px-image-upload');
    if (!image_upload) {
      return;
    }

    this.draggedElement = image_upload;
    this.originalAttributes = this.getImageUploadElements().map(e => this.extractImageUploadAttributes(e));

    // Even though we don't need the data, we need to set it to something to make it work in mobile browsers.
    evt.dataTransfer.setData('text/plain', '');
    evt.dataTransfer.effectAllowed = 'move';
    evt.dataTransfer.setDragImage(image_upload.querySelector('.px-thumbnail'), 0, 0);

    setTimeout(() => {
      // We do this in a timeout so that the feedback image gets created *before*
      // styles activated by this property get applied to it.
      this.setAttribute('data-drag-active', 'true');
    });
  }

  handleDragEnd(evt) {
    const image_upload = evt.target.closest('px-image-upload');
    if (!image_upload) {
      return;
    }

    this.draggedElement = null;
    this.originalAttributes = null;

    this.removeAttribute('data-drag-active');
    this.getImageUploadElements().forEach(e => e.closest('px-option').removeAttribute('data-dropzone-active'));
  }

  handleDragOver(evt) {
    const image_option = evt.target.closest('px-option:has(px-image-upload)');
    if (image_option) {
      evt.preventDefault();
      // For some weird reason dragleave does not seem to fire consistently, so also make sure to clear up
      // any currently marked dropzones when we enter a different one.
      const options = this.getImageUploadElements().map(e => e.closest('px-option'));
      options.forEach(option => {
        if (option === image_option) {
          option.setAttribute('data-dropzone-active', 'true');
        } else {
          option.removeAttribute('data-dropzone-active');
        }
      });
      this.doDragoverPreview(image_option.querySelector('px-image-upload'));
    }
  }

  handleDragLeave(evt) {
    const image_option = evt.target.closest('px-option:has(px-image-upload)');
    // dragleave is triggered on every child element, but we only want to react
    // when we are leaving the px-option element to avoid flickering.
    if (image_option === evt.target) {
      image_option.removeAttribute('data-dropzone-active');
      this.undoDragoverPreview();
    }
  }

  handleDrop(evt) {
    const image_upload = evt.target.querySelector('px-image-upload');
    if (!image_upload) {
      return;
    }

    evt.preventDefault();

    if (this.draggedElement !== image_upload) {
      const new_attributes = this.reorderAttributes(this.originalAttributes, this.draggedElement, image_upload);
      this.applyImageAttributes(new_attributes);
    }
  }

};

customElements.define('px-multi-image-upload', Px.CMS.MultiImageUpload);
